import React from "react"
import NavigationLayout, { Content } from "../components/layout/mainLayout"
import Seo from "../components/Seo"
import { graphql } from "gatsby"
import { rhythm } from "../utilities/typography"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import rendererOptions from "../utilities/rendererOptions"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

const ImpressumPage = ({
  data: {
    contentfulEinfacheSeite: { title, content },
  },
}) => {
  const rendered = renderRichText(content, rendererOptions)

  return (
    <NavigationLayout>
      <Seo title="Impressum" />
      <Content>
        <Container
          maxWidth={"lg"}
          css={`& > *:first-child { margin-bottom: ${rhythm(1)}`}
        >
          <Typography variant={"h1"}>{title}</Typography>
          {rendered}
        </Container>
      </Content>
    </NavigationLayout>
  )
}

export const query = graphql`
  query impressum {
    contentfulEinfacheSeite(urlTitle: { eq: "impressum" }) {
      title
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            __typename
            gatsbyImageData
          }
          contentful_id
          title
          fixed {
            src
          }
        }
      }
    }
  }
`

export default ImpressumPage
